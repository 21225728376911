/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ActivityLogResp } from '../models/activity-log-resp';
import { CcrAdminAddCommentReq } from '../models/ccr-admin-add-comment-req';
import { CcrAdminAssignToBuyerReq } from '../models/ccr-admin-assign-to-buyer-req';
import { CcrAdminCancelReq } from '../models/ccr-admin-cancel-req';
import { CcrAdminChangeDocTypeReq } from '../models/ccr-admin-change-doc-type-req';
import { CcrcapaReviewActionReq } from '../models/ccrcapa-review-action-req';
import { CcrCreatorOptResp } from '../models/ccr-creator-opt-resp';
import { CcrEditApproveReq } from '../models/ccr-edit-approve-req';
import { CcrEditCreatorReviseReq } from '../models/ccr-edit-creator-revise-req';
import { CcrEditDraftActionReq } from '../models/ccr-edit-draft-action-req';
import { CcrEditResp } from '../models/ccr-edit-resp';
import { CcrEditSaveReq } from '../models/ccr-edit-save-req';
import { CcrEditSubmitReq } from '../models/ccr-edit-submit-req';
import { CcrFileStreamResp } from '../models/ccr-file-stream-resp';
import { CcrhmRequestChatReq } from '../models/ccrhm-request-chat-req';
import { CcrhmRequestChatRes } from '../models/ccrhm-request-chat-res';
import { CcrOptApprovedLevelResp } from '../models/ccr-opt-approved-level-resp';
import { CcrOptContractResp } from '../models/ccr-opt-contract-resp';
import { CcrOptIsBuyerResp } from '../models/ccr-opt-is-buyer-resp';
import { CcrOptMgrInitResp } from '../models/ccr-opt-mgr-init-resp';
import { CcrOptMgrResp } from '../models/ccr-opt-mgr-resp';
import { CcrOptPicDefaultResp } from '../models/ccr-opt-pic-default-resp';
import { CcrOptPicInitReq } from '../models/ccr-opt-pic-init-req';
import { CcrOptPicResp } from '../models/ccr-opt-pic-resp';
import { CcrOptPoContactInfoRes } from '../models/ccr-opt-po-contact-info-res';
import { CcrOptPoResp } from '../models/ccr-opt-po-resp';
import { CcrOptProblemResp } from '../models/ccr-opt-problem-resp';
import { CcrOptProductTypeResp } from '../models/ccr-opt-product-type-resp';
import { CcrOptReasonResp } from '../models/ccr-opt-reason-resp';
import { CcrOptRefCompanyResp } from '../models/ccr-opt-ref-company-resp';
import { CcrOptSafetyLevelResp } from '../models/ccr-opt-safety-level-resp';
import { CcrOptStatementWithDataRes } from '../models/ccr-opt-statement-with-data-res';
import { CcrOptVendorResp } from '../models/ccr-opt-vendor-resp';
import { CcrPrepareStatementActionDataReq } from '../models/ccr-prepare-statement-action-data-req';
import { CcrPrepareStatementActionReq } from '../models/ccr-prepare-statement-action-req';
import { CcrRequesterOptResp } from '../models/ccr-requester-opt-resp';
import { CcrStatementHtmlToPdfReq } from '../models/ccr-statement-html-to-pdf-req';
import { CcrWithdrawReq } from '../models/ccr-withdraw-req';
import { DoaApproverItemResp } from '../models/doa-approver-item-resp';
import { ExchangeRateToThbRes } from '../models/exchange-rate-to-thb-res';
import { MasterCapaTemplateRowItemRes } from '../models/master-capa-template-row-item-res';
import { MasterStatementTemplateRowItemRes } from '../models/master-statement-template-row-item-res';
import { OptPoSearchCriteriaReq } from '../models/opt-po-search-criteria-req';
import { StepBarResp } from '../models/step-bar-resp';
import { VenSchUnspscOptDataRes } from '../models/ven-sch-unspsc-opt-data-res';
import { WorklistResp } from '../models/worklist-resp';

@Injectable({ providedIn: 'root' })
export class CcrRequestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCcrRequestGet()` */
  static readonly ApiCcrRequestGetPath = '/api/ccr/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<CcrEditResp> {
    return this.apiCcrRequestGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditResp>): CcrEditResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestStepbarGet()` */
  static readonly ApiCcrRequestStepbarGetPath = '/api/ccr/request/stepbar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestStepbarGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestStepbarGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StepBarResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestStepbarGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StepBarResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestStepbarGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestStepbarGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StepBarResp> {
    return this.apiCcrRequestStepbarGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StepBarResp>): StepBarResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestLogsGet()` */
  static readonly ApiCcrRequestLogsGetPath = '/api/ccr/request/logs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestLogsGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ActivityLogResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestLogsGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ActivityLogResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestLogsGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<Array<ActivityLogResp>> {
    return this.apiCcrRequestLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActivityLogResp>>): Array<ActivityLogResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestWorklistGet()` */
  static readonly ApiCcrRequestWorklistGetPath = '/api/ccr/request/worklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestWorklistGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestWorklistGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorklistResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestWorklistGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorklistResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestWorklistGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestWorklistGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<WorklistResp> {
    return this.apiCcrRequestWorklistGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorklistResp>): WorklistResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestVendorRegEmailsGet()` */
  static readonly ApiCcrRequestVendorRegEmailsGetPath = '/api/ccr/request/vendor-reg-emails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestVendorRegEmailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestVendorRegEmailsGet$Response(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestVendorRegEmailsGetPath, 'get');
    if (params) {
      rb.query('vendorProfileId', params.vendorProfileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestVendorRegEmailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestVendorRegEmailsGet(
    params?: {
      vendorProfileId?: string;
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrRequestVendorRegEmailsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestNewrequestSavePost()` */
  static readonly ApiCcrRequestNewrequestSavePostPath = '/api/ccr/request/newrequest/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestNewrequestSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestNewrequestSavePost$Response(
    params?: {
      body?: CcrEditSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestNewrequestSavePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestNewrequestSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestNewrequestSavePost(
    params?: {
      body?: CcrEditSaveReq
    },
    context?: HttpContext
  ): Observable<CcrEditResp> {
    return this.apiCcrRequestNewrequestSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditResp>): CcrEditResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestNewrequestSubmitPost()` */
  static readonly ApiCcrRequestNewrequestSubmitPostPath = '/api/ccr/request/newrequest/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestNewrequestSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestNewrequestSubmitPost$Response(
    params?: {
      body?: CcrEditSubmitReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestNewrequestSubmitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestNewrequestSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestNewrequestSubmitPost(
    params?: {
      body?: CcrEditSubmitReq
    },
    context?: HttpContext
  ): Observable<CcrEditResp> {
    return this.apiCcrRequestNewrequestSubmitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditResp>): CcrEditResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestDraftSavePost()` */
  static readonly ApiCcrRequestDraftSavePostPath = '/api/ccr/request/draft/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestDraftSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestDraftSavePost$Response(
    params?: {
      requestId?: string;
      body?: CcrEditSaveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestDraftSavePostPath, 'post');
    if (params) {
      rb.query('requestId', params.requestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestDraftSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestDraftSavePost(
    params?: {
      requestId?: string;
      body?: CcrEditSaveReq
    },
    context?: HttpContext
  ): Observable<CcrEditResp> {
    return this.apiCcrRequestDraftSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditResp>): CcrEditResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestDraftActionPost()` */
  static readonly ApiCcrRequestDraftActionPostPath = '/api/ccr/request/draft/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestDraftActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestDraftActionPost$Response(
    params?: {
      body?: CcrEditDraftActionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestDraftActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestDraftActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestDraftActionPost(
    params?: {
      body?: CcrEditDraftActionReq
    },
    context?: HttpContext
  ): Observable<CcrEditResp> {
    return this.apiCcrRequestDraftActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditResp>): CcrEditResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestCreatorrevisePost()` */
  static readonly ApiCcrRequestCreatorrevisePostPath = '/api/ccr/request/creatorrevise';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestCreatorrevisePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestCreatorrevisePost$Response(
    params?: {
      body?: CcrEditCreatorReviseReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrEditResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestCreatorrevisePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrEditResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestCreatorrevisePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestCreatorrevisePost(
    params?: {
      body?: CcrEditCreatorReviseReq
    },
    context?: HttpContext
  ): Observable<CcrEditResp> {
    return this.apiCcrRequestCreatorrevisePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrEditResp>): CcrEditResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestApprovePost()` */
  static readonly ApiCcrRequestApprovePostPath = '/api/ccr/request/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestApprovePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestApprovePost$Response(
    params?: {
      body?: CcrEditApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestApprovePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestApprovePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestApprovePost(
    params?: {
      body?: CcrEditApproveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestApprovePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestPrepareStatementActionPost()` */
  static readonly ApiCcrRequestPrepareStatementActionPostPath = '/api/ccr/request/prepare-statement/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestPrepareStatementActionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestPrepareStatementActionPost$Response(
    params?: {
      body?: CcrPrepareStatementActionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestPrepareStatementActionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestPrepareStatementActionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestPrepareStatementActionPost(
    params?: {
      body?: CcrPrepareStatementActionReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestPrepareStatementActionPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestPrepareStatementSavePost()` */
  static readonly ApiCcrRequestPrepareStatementSavePostPath = '/api/ccr/request/prepare-statement/save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestPrepareStatementSavePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestPrepareStatementSavePost$Response(
    params?: {
      requestId?: string;
      body?: CcrPrepareStatementActionDataReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestPrepareStatementSavePostPath, 'post');
    if (params) {
      rb.query('requestId', params.requestId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestPrepareStatementSavePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestPrepareStatementSavePost(
    params?: {
      requestId?: string;
      body?: CcrPrepareStatementActionDataReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestPrepareStatementSavePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestCapaReviewPost()` */
  static readonly ApiCcrRequestCapaReviewPostPath = '/api/ccr/request/capa-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestCapaReviewPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestCapaReviewPost$Response(
    params?: {
      body?: CcrcapaReviewActionReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestCapaReviewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestCapaReviewPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestCapaReviewPost(
    params?: {
      body?: CcrcapaReviewActionReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestCapaReviewPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestApproveHoldPost()` */
  static readonly ApiCcrRequestApproveHoldPostPath = '/api/ccr/request/approve-hold';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestApproveHoldPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestApproveHoldPost$Response(
    params?: {
      body?: CcrEditApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestApproveHoldPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestApproveHoldPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestApproveHoldPost(
    params?: {
      body?: CcrEditApproveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestApproveHoldPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestApproveFinalPost()` */
  static readonly ApiCcrRequestApproveFinalPostPath = '/api/ccr/request/approve-final';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestApproveFinalPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestApproveFinalPost$Response(
    params?: {
      body?: CcrEditApproveReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestApproveFinalPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestApproveFinalPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestApproveFinalPost(
    params?: {
      body?: CcrEditApproveReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestApproveFinalPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestWithdrawPost()` */
  static readonly ApiCcrRequestWithdrawPostPath = '/api/ccr/request/withdraw';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestWithdrawPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestWithdrawPost$Response(
    params?: {
      body?: CcrWithdrawReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestWithdrawPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestWithdrawPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestWithdrawPost(
    params?: {
      body?: CcrWithdrawReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestWithdrawPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptCreatorInitGet()` */
  static readonly ApiCcrRequestOptCreatorInitGetPath = '/api/ccr/request/opt/creator/init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptCreatorInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCreatorInitGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrCreatorOptResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptCreatorInitGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrCreatorOptResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptCreatorInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCreatorInitGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<CcrCreatorOptResp> {
    return this.apiCcrRequestOptCreatorInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrCreatorOptResp>): CcrCreatorOptResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptRequesterInitGet()` */
  static readonly ApiCcrRequestOptRequesterInitGetPath = '/api/ccr/request/opt/requester/init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptRequesterInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptRequesterInitGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrRequesterOptResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptRequesterInitGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrRequesterOptResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptRequesterInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptRequesterInitGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<CcrRequesterOptResp> {
    return this.apiCcrRequestOptRequesterInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrRequesterOptResp>): CcrRequesterOptResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptRequesterStartwithGet()` */
  static readonly ApiCcrRequestOptRequesterStartwithGetPath = '/api/ccr/request/opt/requester/startwith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptRequesterStartwithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptRequesterStartwithGet$Response(
    params?: {
      startWith?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrRequesterOptResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptRequesterStartwithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrRequesterOptResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptRequesterStartwithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptRequesterStartwithGet(
    params?: {
      startWith?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrRequesterOptResp>> {
    return this.apiCcrRequestOptRequesterStartwithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrRequesterOptResp>>): Array<CcrRequesterOptResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptApprovedlevelGet()` */
  static readonly ApiCcrRequestOptApprovedlevelGetPath = '/api/ccr/request/opt/approvedlevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptApprovedlevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptApprovedlevelGet$Response(
    params?: {
      docType?: string;
      refCompanyCode?: string;
      lossEstimateTHB?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptApprovedLevelResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptApprovedlevelGetPath, 'get');
    if (params) {
      rb.query('docType', params.docType, {});
      rb.query('refCompanyCode', params.refCompanyCode, {});
      rb.query('lossEstimateTHB', params.lossEstimateTHB, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptApprovedLevelResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptApprovedlevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptApprovedlevelGet(
    params?: {
      docType?: string;
      refCompanyCode?: string;
      lossEstimateTHB?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<CcrOptApprovedLevelResp> {
    return this.apiCcrRequestOptApprovedlevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptApprovedLevelResp>): CcrOptApprovedLevelResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptListOfLevelGet()` */
  static readonly ApiCcrRequestOptListOfLevelGetPath = '/api/ccr/request/opt/listOfLevel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptListOfLevelGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptListOfLevelGet$Response(
    params?: {
      docType?: string;
      refCompanyCode?: string;
      lossEstimateTHB?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DoaApproverItemResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptListOfLevelGetPath, 'get');
    if (params) {
      rb.query('docType', params.docType, {});
      rb.query('refCompanyCode', params.refCompanyCode, {});
      rb.query('lossEstimateTHB', params.lossEstimateTHB, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DoaApproverItemResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptListOfLevelGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptListOfLevelGet(
    params?: {
      docType?: string;
      refCompanyCode?: string;
      lossEstimateTHB?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<DoaApproverItemResp> {
    return this.apiCcrRequestOptListOfLevelGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<DoaApproverItemResp>): DoaApproverItemResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptMgrInitGet()` */
  static readonly ApiCcrRequestOptMgrInitGetPath = '/api/ccr/request/opt/mgr/init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptMgrInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptMgrInitGet$Response(
    params?: {
      requiredApprovedLevel?: number;
      requesterId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptMgrInitResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptMgrInitGetPath, 'get');
    if (params) {
      rb.query('requiredApprovedLevel', params.requiredApprovedLevel, {});
      rb.query('requesterId', params.requesterId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptMgrInitResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptMgrInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptMgrInitGet(
    params?: {
      requiredApprovedLevel?: number;
      requesterId?: string;
    },
    context?: HttpContext
  ): Observable<CcrOptMgrInitResp> {
    return this.apiCcrRequestOptMgrInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptMgrInitResp>): CcrOptMgrInitResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptFinalMgrRequesterInitGet()` */
  static readonly ApiCcrRequestOptFinalMgrRequesterInitGetPath = '/api/ccr/request/opt/final-mgr/requester/init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptFinalMgrRequesterInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptFinalMgrRequesterInitGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptMgrInitResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptFinalMgrRequesterInitGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptMgrInitResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptFinalMgrRequesterInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptFinalMgrRequesterInitGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<CcrOptMgrInitResp> {
    return this.apiCcrRequestOptFinalMgrRequesterInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptMgrInitResp>): CcrOptMgrInitResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptFinalMgrPicInitGet()` */
  static readonly ApiCcrRequestOptFinalMgrPicInitGetPath = '/api/ccr/request/opt/final-mgr/pic/init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptFinalMgrPicInitGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptFinalMgrPicInitGet$Response(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptMgrInitResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptFinalMgrPicInitGetPath, 'get');
    if (params) {
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptMgrInitResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptFinalMgrPicInitGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptFinalMgrPicInitGet(
    params?: {
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<CcrOptMgrInitResp> {
    return this.apiCcrRequestOptFinalMgrPicInitGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptMgrInitResp>): CcrOptMgrInitResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptMgrStartwithGet()` */
  static readonly ApiCcrRequestOptMgrStartwithGetPath = '/api/ccr/request/opt/mgr/startwith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptMgrStartwithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptMgrStartwithGet$Response(
    params?: {
      approvedLevel?: number;
      startWith?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptMgrResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptMgrStartwithGetPath, 'get');
    if (params) {
      rb.query('approvedLevel', params.approvedLevel, {});
      rb.query('startWith', params.startWith, {});
      rb.query('workGroupId', params.workGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptMgrResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptMgrStartwithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptMgrStartwithGet(
    params?: {
      approvedLevel?: number;
      startWith?: string;
      workGroupId?: number;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptMgrResp>> {
    return this.apiCcrRequestOptMgrStartwithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptMgrResp>>): Array<CcrOptMgrResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptPoSearchPost()` */
  static readonly ApiCcrRequestOptPoSearchPostPath = '/api/ccr/request/opt/po/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptPoSearchPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestOptPoSearchPost$Response(
    params?: {
      body?: OptPoSearchCriteriaReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptPoResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptPoSearchPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptPoResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptPoSearchPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestOptPoSearchPost(
    params?: {
      body?: OptPoSearchCriteriaReq
    },
    context?: HttpContext
  ): Observable<Array<CcrOptPoResp>> {
    return this.apiCcrRequestOptPoSearchPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptPoResp>>): Array<CcrOptPoResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptPoContactInfoGet()` */
  static readonly ApiCcrRequestOptPoContactInfoGetPath = '/api/ccr/request/opt/po/contact-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptPoContactInfoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptPoContactInfoGet$Response(
    params?: {
      poNo?: string;
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptPoContactInfoRes>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptPoContactInfoGetPath, 'get');
    if (params) {
      rb.query('poNo', params.poNo, {});
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptPoContactInfoRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptPoContactInfoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptPoContactInfoGet(
    params?: {
      poNo?: string;
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<CcrOptPoContactInfoRes> {
    return this.apiCcrRequestOptPoContactInfoGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptPoContactInfoRes>): CcrOptPoContactInfoRes => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptContractSearchGet()` */
  static readonly ApiCcrRequestOptContractSearchGetPath = '/api/ccr/request/opt/contract/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptContractSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptContractSearchGet$Response(
    params?: {
      keyword?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptContractResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptContractSearchGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptContractResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptContractSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptContractSearchGet(
    params?: {
      keyword?: string;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptContractResp>> {
    return this.apiCcrRequestOptContractSearchGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptContractResp>>): Array<CcrOptContractResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptProductTypeAllGet()` */
  static readonly ApiCcrRequestOptProductTypeAllGetPath = '/api/ccr/request/opt/product-type/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptProductTypeAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptProductTypeAllGet$Response(
    params?: {
      selectedId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptProductTypeResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptProductTypeAllGetPath, 'get');
    if (params) {
      rb.query('selectedId', params.selectedId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptProductTypeResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptProductTypeAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptProductTypeAllGet(
    params?: {
      selectedId?: number;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptProductTypeResp>> {
    return this.apiCcrRequestOptProductTypeAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptProductTypeResp>>): Array<CcrOptProductTypeResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptProblemAllGet()` */
  static readonly ApiCcrRequestOptProblemAllGetPath = '/api/ccr/request/opt/problem/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptProblemAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptProblemAllGet$Response(
    params?: {
      selectedId?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptProblemResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptProblemAllGetPath, 'get');
    if (params) {
      rb.query('selectedId', params.selectedId, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptProblemResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptProblemAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptProblemAllGet(
    params?: {
      selectedId?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptProblemResp>> {
    return this.apiCcrRequestOptProblemAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptProblemResp>>): Array<CcrOptProblemResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptCurrencyAllGet()` */
  static readonly ApiCcrRequestOptCurrencyAllGetPath = '/api/ccr/request/opt/currency/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptCurrencyAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCurrencyAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptCurrencyAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptCurrencyAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCurrencyAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrRequestOptCurrencyAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptMaterialUnitAllGet()` */
  static readonly ApiCcrRequestOptMaterialUnitAllGetPath = '/api/ccr/request/opt/materialUnit/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptMaterialUnitAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptMaterialUnitAllGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptMaterialUnitAllGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptMaterialUnitAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptMaterialUnitAllGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrRequestOptMaterialUnitAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptReasonAllGet()` */
  static readonly ApiCcrRequestOptReasonAllGetPath = '/api/ccr/request/opt/reason/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptReasonAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptReasonAllGet$Response(
    params?: {
      selectedId?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptReasonResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptReasonAllGetPath, 'get');
    if (params) {
      rb.query('selectedId', params.selectedId, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptReasonResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptReasonAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptReasonAllGet(
    params?: {
      selectedId?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptReasonResp>> {
    return this.apiCcrRequestOptReasonAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptReasonResp>>): Array<CcrOptReasonResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptRefcompanyAllGet()` */
  static readonly ApiCcrRequestOptRefcompanyAllGetPath = '/api/ccr/request/opt/refcompany/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptRefcompanyAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptRefcompanyAllGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptRefCompanyResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptRefcompanyAllGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptRefCompanyResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptRefcompanyAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptRefcompanyAllGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptRefCompanyResp>> {
    return this.apiCcrRequestOptRefcompanyAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptRefCompanyResp>>): Array<CcrOptRefCompanyResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptVendorContainGet()` */
  static readonly ApiCcrRequestOptVendorContainGetPath = '/api/ccr/request/opt/vendor/contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptVendorContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptVendorContainGet$Response(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptVendorResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptVendorContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('workGroupList', params.workGroupList, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptVendorResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptVendorContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptVendorContainGet(
    params?: {
      keyword?: string;
      workGroupList?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptVendorResp>> {
    return this.apiCcrRequestOptVendorContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptVendorResp>>): Array<CcrOptVendorResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptIsBuyerGet()` */
  static readonly ApiCcrRequestOptIsBuyerGetPath = '/api/ccr/request/opt/is-buyer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptIsBuyerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptIsBuyerGet$Response(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptIsBuyerResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptIsBuyerGetPath, 'get');
    if (params) {
      rb.query('fkWorkGroupId', params.fkWorkGroupId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptIsBuyerResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptIsBuyerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptIsBuyerGet(
    params?: {
      fkWorkGroupId?: number;
    },
    context?: HttpContext
  ): Observable<CcrOptIsBuyerResp> {
    return this.apiCcrRequestOptIsBuyerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptIsBuyerResp>): CcrOptIsBuyerResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptPicStartwithGet()` */
  static readonly ApiCcrRequestOptPicStartwithGetPath = '/api/ccr/request/opt/pic/startwith';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptPicStartwithGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptPicStartwithGet$Response(
    params?: {
      startWith?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptPicResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptPicStartwithGetPath, 'get');
    if (params) {
      rb.query('startWith', params.startWith, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptPicResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptPicStartwithGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptPicStartwithGet(
    params?: {
      startWith?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptPicResp>> {
    return this.apiCcrRequestOptPicStartwithGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptPicResp>>): Array<CcrOptPicResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptPicInitPost()` */
  static readonly ApiCcrRequestOptPicInitPostPath = '/api/ccr/request/opt/pic/init';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptPicInitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestOptPicInitPost$Response(
    params?: {
      body?: CcrOptPicInitReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptPicResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptPicInitPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptPicResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptPicInitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestOptPicInitPost(
    params?: {
      body?: CcrOptPicInitReq
    },
    context?: HttpContext
  ): Observable<CcrOptPicResp> {
    return this.apiCcrRequestOptPicInitPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptPicResp>): CcrOptPicResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptBuyerDefaultContractGet()` */
  static readonly ApiCcrRequestOptBuyerDefaultContractGetPath = '/api/ccr/request/opt/buyer-default/contract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptBuyerDefaultContractGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptBuyerDefaultContractGet$Response(
    params?: {
      contractNo?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptPicDefaultResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptBuyerDefaultContractGetPath, 'get');
    if (params) {
      rb.query('contractNo', params.contractNo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptPicDefaultResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptBuyerDefaultContractGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptBuyerDefaultContractGet(
    params?: {
      contractNo?: string;
    },
    context?: HttpContext
  ): Observable<CcrOptPicDefaultResp> {
    return this.apiCcrRequestOptBuyerDefaultContractGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptPicDefaultResp>): CcrOptPicDefaultResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptSafetyLevelAllGet()` */
  static readonly ApiCcrRequestOptSafetyLevelAllGetPath = '/api/ccr/request/opt/safety-level/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptSafetyLevelAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptSafetyLevelAllGet$Response(
    params?: {
      selectedCode?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrOptSafetyLevelResp>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptSafetyLevelAllGetPath, 'get');
    if (params) {
      rb.query('selectedCode', params.selectedCode, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrOptSafetyLevelResp>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptSafetyLevelAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptSafetyLevelAllGet(
    params?: {
      selectedCode?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<CcrOptSafetyLevelResp>> {
    return this.apiCcrRequestOptSafetyLevelAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrOptSafetyLevelResp>>): Array<CcrOptSafetyLevelResp> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptUnspscContainGet()` */
  static readonly ApiCcrRequestOptUnspscContainGetPath = '/api/ccr/request/opt/unspsc/contain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptUnspscContainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptUnspscContainGet$Response(
    params?: {
      keyword?: string;
      max?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptUnspscContainGetPath, 'get');
    if (params) {
      rb.query('keyword', params.keyword, {});
      rb.query('max', params.max, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptUnspscContainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptUnspscContainGet(
    params?: {
      keyword?: string;
      max?: number;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiCcrRequestOptUnspscContainGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptUnspscGet()` */
  static readonly ApiCcrRequestOptUnspscGetPath = '/api/ccr/request/opt/unspsc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptUnspscGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptUnspscGet$Response(
    params?: {
      aribaCodeId?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VenSchUnspscOptDataRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptUnspscGetPath, 'get');
    if (params) {
      rb.query('aribaCodeId', params.aribaCodeId, {});
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VenSchUnspscOptDataRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptUnspscGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptUnspscGet(
    params?: {
      aribaCodeId?: string;
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<VenSchUnspscOptDataRes>> {
    return this.apiCcrRequestOptUnspscGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VenSchUnspscOptDataRes>>): Array<VenSchUnspscOptDataRes> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptStatementTemplateGet()` */
  static readonly ApiCcrRequestOptStatementTemplateGetPath = '/api/ccr/request/opt/statement-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptStatementTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptStatementTemplateGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterStatementTemplateRowItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptStatementTemplateGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterStatementTemplateRowItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptStatementTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptStatementTemplateGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterStatementTemplateRowItemRes>> {
    return this.apiCcrRequestOptStatementTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterStatementTemplateRowItemRes>>): Array<MasterStatementTemplateRowItemRes> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptStatementTemplateInitDataGet()` */
  static readonly ApiCcrRequestOptStatementTemplateInitDataGetPath = '/api/ccr/request/opt/statement-template/init-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptStatementTemplateInitDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptStatementTemplateInitDataGet$Response(
    params?: {
      statementTemplateId?: string;
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrOptStatementWithDataRes>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptStatementTemplateInitDataGetPath, 'get');
    if (params) {
      rb.query('statementTemplateId', params.statementTemplateId, {});
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrOptStatementWithDataRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptStatementTemplateInitDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptStatementTemplateInitDataGet(
    params?: {
      statementTemplateId?: string;
      requestId?: string;
    },
    context?: HttpContext
  ): Observable<CcrOptStatementWithDataRes> {
    return this.apiCcrRequestOptStatementTemplateInitDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrOptStatementWithDataRes>): CcrOptStatementWithDataRes => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptCapaTemplateGet()` */
  static readonly ApiCcrRequestOptCapaTemplateGetPath = '/api/ccr/request/opt/capa-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptCapaTemplateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCapaTemplateGet$Response(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MasterCapaTemplateRowItemRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptCapaTemplateGetPath, 'get');
    if (params) {
      rb.query('workgroupIds', params.workgroupIds, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCapaTemplateRowItemRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptCapaTemplateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCapaTemplateGet(
    params?: {
      workgroupIds?: Array<number>;
    },
    context?: HttpContext
  ): Observable<Array<MasterCapaTemplateRowItemRes>> {
    return this.apiCcrRequestOptCapaTemplateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCapaTemplateRowItemRes>>): Array<MasterCapaTemplateRowItemRes> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptCapaTemplateContentGet()` */
  static readonly ApiCcrRequestOptCapaTemplateContentGetPath = '/api/ccr/request/opt/capa-template/content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptCapaTemplateContentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCapaTemplateContentGet$Response(
    params?: {
      capaTemplateId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptCapaTemplateContentGetPath, 'get');
    if (params) {
      rb.query('capaTemplateId', params.capaTemplateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptCapaTemplateContentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptCapaTemplateContentGet(
    params?: {
      capaTemplateId?: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.apiCcrRequestOptCapaTemplateContentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptExchangeRateToThbGet()` */
  static readonly ApiCcrRequestOptExchangeRateToThbGetPath = '/api/ccr/request/opt/exchange-rate-to-thb';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptExchangeRateToThbGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptExchangeRateToThbGet$Response(
    params?: {
      fromCurr?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ExchangeRateToThbRes>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptExchangeRateToThbGetPath, 'get');
    if (params) {
      rb.query('fromCurr', params.fromCurr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExchangeRateToThbRes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptExchangeRateToThbGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptExchangeRateToThbGet(
    params?: {
      fromCurr?: string;
    },
    context?: HttpContext
  ): Observable<ExchangeRateToThbRes> {
    return this.apiCcrRequestOptExchangeRateToThbGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateToThbRes>): ExchangeRateToThbRes => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestOptOneTimeVendorCodesGet()` */
  static readonly ApiCcrRequestOptOneTimeVendorCodesGetPath = '/api/ccr/request/opt/one-time-vendor-codes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestOptOneTimeVendorCodesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptOneTimeVendorCodesGet$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestOptOneTimeVendorCodesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestOptOneTimeVendorCodesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestOptOneTimeVendorCodesGet(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.apiCcrRequestOptOneTimeVendorCodesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestStatementPreviewToPdfPost()` */
  static readonly ApiCcrRequestStatementPreviewToPdfPostPath = '/api/ccr/request/statement-preview-to-pdf';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestStatementPreviewToPdfPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestStatementPreviewToPdfPost$Response(
    params?: {
      body?: CcrStatementHtmlToPdfReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CcrFileStreamResp>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestStatementPreviewToPdfPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CcrFileStreamResp>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestStatementPreviewToPdfPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestStatementPreviewToPdfPost(
    params?: {
      body?: CcrStatementHtmlToPdfReq
    },
    context?: HttpContext
  ): Observable<CcrFileStreamResp> {
    return this.apiCcrRequestStatementPreviewToPdfPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CcrFileStreamResp>): CcrFileStreamResp => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestAdminAddCommentPost()` */
  static readonly ApiCcrRequestAdminAddCommentPostPath = '/api/ccr/request/admin/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestAdminAddCommentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminAddCommentPost$Response(
    params?: {
      body?: CcrAdminAddCommentReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestAdminAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestAdminAddCommentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminAddCommentPost(
    params?: {
      body?: CcrAdminAddCommentReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestAdminAddCommentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestAdminChangeDocTypePost()` */
  static readonly ApiCcrRequestAdminChangeDocTypePostPath = '/api/ccr/request/admin/change-doc-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestAdminChangeDocTypePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminChangeDocTypePost$Response(
    params?: {
      body?: CcrAdminChangeDocTypeReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestAdminChangeDocTypePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestAdminChangeDocTypePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminChangeDocTypePost(
    params?: {
      body?: CcrAdminChangeDocTypeReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestAdminChangeDocTypePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestAdminChangePicPost()` */
  static readonly ApiCcrRequestAdminChangePicPostPath = '/api/ccr/request/admin/change-pic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestAdminChangePicPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminChangePicPost$Response(
    params?: {
      body?: CcrAdminAssignToBuyerReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestAdminChangePicPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestAdminChangePicPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminChangePicPost(
    params?: {
      body?: CcrAdminAssignToBuyerReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestAdminChangePicPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestAdminCancelPost()` */
  static readonly ApiCcrRequestAdminCancelPostPath = '/api/ccr/request/admin/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestAdminCancelPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminCancelPost$Response(
    params?: {
      body?: CcrAdminCancelReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestAdminCancelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestAdminCancelPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestAdminCancelPost(
    params?: {
      body?: CcrAdminCancelReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestAdminCancelPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestChatBoxMessagesGet()` */
  static readonly ApiCcrRequestChatBoxMessagesGetPath = '/api/ccr/request/chat-box/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestChatBoxMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestChatBoxMessagesGet$Response(
    params?: {
      ccrRequestId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CcrhmRequestChatRes>>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestChatBoxMessagesGetPath, 'get');
    if (params) {
      rb.query('ccrRequestId', params.ccrRequestId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CcrhmRequestChatRes>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestChatBoxMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCcrRequestChatBoxMessagesGet(
    params?: {
      ccrRequestId?: string;
    },
    context?: HttpContext
  ): Observable<Array<CcrhmRequestChatRes>> {
    return this.apiCcrRequestChatBoxMessagesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CcrhmRequestChatRes>>): Array<CcrhmRequestChatRes> => r.body)
    );
  }

  /** Path part for operation `apiCcrRequestChatBoxMessagePost()` */
  static readonly ApiCcrRequestChatBoxMessagePostPath = '/api/ccr/request/chat-box/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCcrRequestChatBoxMessagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestChatBoxMessagePost$Response(
    params?: {
      body?: CcrhmRequestChatReq
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CcrRequestService.ApiCcrRequestChatBoxMessagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCcrRequestChatBoxMessagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiCcrRequestChatBoxMessagePost(
    params?: {
      body?: CcrhmRequestChatReq
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCcrRequestChatBoxMessagePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
